/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const centre_examsRoutes = {
  path: '/exams_centers',
  component: Layout,
  redirect: '/exams_centers/index',
  meta: { permissions: ['manage exams center'] },
  children: [
    {
      path: 'exams_centers/list',
      component: () => import('@/views/exams_centers/List'),
      name: 'Centres d\'examens',
      meta: { title: 'Centres d\'examens', icon: 'star', noCache: true, roles: ['admin', 'candidat'] },
    },
  ],
};

export default centre_examsRoutes;
