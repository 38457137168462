/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const responsableCertification = {
  path: '/responsable_certification',
  redirect: '#',
  name: 'Responsable Certification',
  component: Layout,
  meta: {
    roles: ['admin'],
    permissions: ['view menu responsable certification'],
    title: 'Validation des demandes (RC)',
    icon: 'role',
  },
  children: [
    {
      path: 'responsable/certification/list',
      component: () => import('@/views/responsableCertification/List'),
      name: 'Gestion des dossiers',
      meta: { title: 'Validation de la demande', icon: 'people', noCache: true, permissions: ['view menu responsable certification'] },
    },
    {
      path: 'responsable/surveillance/list',
      component: () => import('@/views/responsableCertification/ListSurveillance'),
      name: 'Gestion des surveillance',
      meta: { title: 'Revue de la surveillance', icon: 'eye', noCache: true, permissions: ['view menu responsable certification'] },
    },
    {
      path: 'detail_surveillance/user/:id(\\d+)',
      component: () => import('@/views/responsableCertification/componentSurveillance/dossier_surveillance'),
      name: 'Surveillance',
      props: true,
      meta: { title: 'Surveillance', noCache: true },
      hidden: true,
    },
    {
      path: 'detail_certification/user/:id(\\d+)/:cert_id(\\d+)',
      component: () => import('@/views/responsableCertification/detail'),
      name: 'Detail',
      props: true,
      meta: { title: 'Detail', noCache: true },
      hidden: true,
    },
  ],
};

export default responsableCertification;
