import Layout from '@/layout';

const certificationRoutes = {
  path: '/certifications',
  component: Layout,
  redirect: '#',
  // /certifications/certifications/list
  name: 'Gestion de la certification',
  meta: {
    roles: ['admin'],
    title: 'Gestion de la certification',
    icon: 'list',
  },
  alwaysShow: true,
  children: [
    {
      path: 'certifications/list',
      component: () => import('@/views/certifications/List'),
      name: 'Gestion des certifications',
      meta: {
        title: 'Gestion des certifications',
        icon: 'list',
        noCache: true,
      },
    },
    {
      path: 'exam/list',
      component: () => import('@/views/exams/List'),
      name: 'Gestion des examens',
      meta: {
        title: 'Gestion des examens',
        icon: 'education',
        noCache: true,
        roles: ['admin', 'candidat'],
      },
    },
    {
      path: '/exams/exam/create',
      component: () => import('@/views/exams/Create'),
      name: 'exam_create',
      meta: { title: 'Ajouter un examens' },
      hidden: true,
    },
    {
      path: '/exams/exam/edit/:id(\\d+)',
      component: () => import('@/views/exams/Edit'),
      name: 'exam_create_edit',
      meta: { title: 'Modifier examen' },
      hidden: true,
    },
    {
      path: '/exams/exam/show/:id(\\d+)',
      component: () => import('@/views/exams/components/ExamDetail'),
      name: 'exam_create_show',
      meta: { title: "Information d'examen" },
      hidden: true,
      props: true,
    },
    {
      path: '/exams/module/questions/:id(\\d+)',
      component: () => import('@/views/exams/components/QuestionCreate'),
      name: 'exam_create_questions',
      meta: { title: "Information d'examen" },
      hidden: true,
      props: true,
    },
    // ////// Reponses Routes //////////////
    {
      path: '/reponses/add/:id(\\d+)/:id_module(\\d+)',
      component: () => import('@/views/reponse/add'),
      name: 'reponse_create',
      meta: { title: 'Ajout une réponse' },
      hidden: true,
      props: true,
    },
    {
      path: 'categories/list',
      component: () => import('@/views/categories/List'),
      name: 'Gestion des catégories',
      meta: {
        title: 'Gestion des catégories',
        icon: 'list',
        noCache: true,
        roles: ['admin', 'candidat'],
      },
    },
  ],
};

export default certificationRoutes;
