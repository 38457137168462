/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const adminRoutes = {
  path: '/administrator',
  component: Layout,
  redirect: '#',
  name: 'Administrateur',
  alwaysShow: true,
  meta: {
    title: 'Administrateur',
    icon: 'admin',
    permissions: ['view menu administrator'],
  },
  children: [
    /** User managements */
    {
      path: 'users/edit/:id(\\d+)',
      component: () => import('@/views/users/UserProfile'),
      name: 'Profil de l\'utilisateur',
      meta: { title: 'Profil de l\'utilisateur', noCache: true, permissions: ['manage user'] },
      hidden: true,
    },
    {
      path: 'users',
      component: () => import('@/views/users/List'),
      name: 'UserList',
      meta: { title: 'users', icon: 'user', permissions: ['manage user'] },
    },
    /** Role and permission */
    {
      path: 'roles',
      component: () => import('@/views/role-permission/List'),
      name: 'RoleList',
      meta: { title: 'rolePermission', icon: 'role', permissions: ['manage permission'] },
    }, {
      path: 'articles/create',
      component: () => import('@/views/articles/Create'),
      name: 'CreateArticle',
      meta: { title: 'createArticle', icon: 'edit', permissions: ['manage article'] },
      hidden: true,
    },
    {
      path: 'articles/edit/:id(\\d+)',
      component: () => import('@/views/articles/Edit'),
      name: 'EditArticle',
      meta: { title: 'editArticle', noCache: true, permissions: ['manage article'] },
      hidden: true,
    },
    {
      path: 'articles',
      component: () => import('@/views/articles/List'),
      name: 'ArticleList',
      meta: { title: 'articleList', icon: 'list', permissions: ['manage article'] },
      hidden: true,
    },
    {
      path: 'certifications/list',
      component: () => import('@/views/directiveDemande/List'),
      name: 'Gestion des documents',
      meta: { title: 'Gestion des documents', icon: 'list', noCache: true },
      hidden: true,
    },
    {
      path: 'backup',
      component: () => import('@/views/backup/index'),
      name: 'Backup',
      meta: { title: 'Sauvegarde', icon: 'zip', permissions: ['manage user'] },
    },
  ],
};

export default adminRoutes;
