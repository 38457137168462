import request from '@/utils/request';

export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: data,
  });
}

export function getInfo(token) {
  return request({
    url: '/user',
    method: 'get',
  });
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post',
  });
}

export function csrf() {
  return request({
    url: '/sanctum/csrf-cookie',
    method: 'get',
  });
}

export function register(data) {
  return request({
    url: '/register',
    method: 'post',
    data: data,
  });
}

export function sendPasswordResetLink(data) {
  return request({
    url: '/reset-password',
    method: 'post',
    data: data,
  });
}
export function callResetPassword(data) {
  return request({
    url: '/reset/password',
    method: 'post',
    data: data,
  });
}

export function getAllCertifs(){
  return request({
    url: '/certifs',
    method: 'get',
  });
}

