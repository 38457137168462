/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const examTheoriqueRoutes = {
  path: '/examen',
  component: Layout,
  hidden: true,
  meta: { roles: ['candidat'] },
  children: [
    {
      path: '/exams_theorique/:id(\\d+)/:formelle_id(\\d+)',
      component: () => import('@/views/exam_theorique/index'),
      name: 'Passage d\'examen',
      meta: { title: 'Passage d\'examen', noCache: true, roles: ['candidat'] },
      hidden: true,
      props: true,
    },
    {
      path: '/examen_finale/:id(\\d+)/:formelle_id(\\d+)/:sessionTheor_id(\\d+)',
      component: () => import('@/views/exam_theorique/final_exam/index'),
      name: 'Examen finale',
      meta: { title: 'Examen finale', noCache: true, roles: ['candidat'] },
      hidden: true,
      props: true,
      async beforeEnter(to, from, next) {
        console.log(from.name);
        if (from.name !== 'Passage d\'examen') {
          next({ name: 'dashboard' });
        } else {
          next();
        }
      },
    },
    {
      path: '/exam_page/:id(\\d+)/:formelle_id(\\d+)/:sessionTheor_id(\\d+)',
      component: () => import('@/views/exam_theorique/final_exam/exam'),
      name: 'Page examen',
      meta: { title: 'Page d\'examen', noCache: true, roles: ['candidat'] },
      hidden: true,
      props: true,

      async beforeEnter(to, from, next) {
        console.log('from : ', from);
        if (from.name !== 'Passage d\'examen' && from.name !== 'Examen finale') {
          next({ name: 'certification' });
        } else {
          console.log('next : ', next);
          next();
        }
      },
    },
  ],
};

export default examTheoriqueRoutes;
