import Layout from '@/layout';

const gestCandidatRoutes = {
  path: '/gestion_candidat',
  redirect: '#',
  name: 'Gestion Candidat',
  component: Layout,
  meta: {
    permissions: ['view menu gestion candidat'],
    title: 'Gestion de l\'évaluation',
    icon: 'component',
  },
  children: [
    {
      path: 'gestion_candidat/session_examen',
      component: () => import('@/views/gestCandidat/SessionExamen'),
      name: 'Session d\'Examen Théorique',
      meta: { title: 'Planification examen théorique', icon: 'table', noCache: true, roles: ['admin', 'candidat'], permissions: ['view menu gestion candidat'] },
    },
    {
      path: 'edit_session/:id(\\d+)',
      component: () => import('@/views/gestCandidat/EditSession'),
      name: 'Edit Session',
      meta: { title: 'Edit Session', icon: 'tree', noCache: true, roles: ['admin', 'candidat'], permissions: ['view menu gestion candidat'] },
      hidden: true,
    },
    {
      path: 'epreuve_pratique/:id(\\d+)',
      component: () => import('@/views/examen_pratique/new'),
      name: 'NewExam',
      meta: { title: 'Examen Pratique', icon: 'tree', noCache: true, roles: ['admin', 'candidat'], permissions: ['view menu gestion candidat'] },
      hidden: true,
    },
    {
      path: 'gestion_candidat/session_examen_pratique',
      component: () => import('@/views/gestCandidat/SessionPratique/SessionPratique'),
      name: 'Session Pratique',
      meta: { title: 'Planification examen pratique', icon: 'table', noCache: true, roles: ['admin', 'candidat'], permissions: ['view menu gestion candidat'] },
    },
    {
      path: 'SessionPratique/edit_session_pratique/:id(\\d+)',
      component: () => import('@/views/gestCandidat/SessionPratique/EditSessionPratique'),
      name: 'Edit Session Pratique',
      meta: { title: 'Edit Session Pratique', icon: 'nested', noCache: true, roles: ['admin', 'candidat'], permissions: ['view menu gestion candidat'] },
      hidden: true,
    },
    {
      path: 'gestion_candidat/list',
      component: () => import('@/views/gestCandidat/List'),
      name: 'Gestion Candidat',
      meta: { title: 'Résultats des examens', icon: 'edit', noCache: true, roles: ['admin', 'candidat'], permissions: ['view menu gestion candidat'] },
    },
    {
      path: 'gestion_candidat/certificatCandit',
      component: () => import('@/views/gestCandidat/CertificatCandit'),
      name: 'Certificat',
      meta: { title: 'Génération du certificat', icon: 'skill', noCache: true, roles: ['admin', 'candidat'], permissions: ['view menu gestion candidat'] },
    },
    {
      path: 'gestion_candidat/surveillance',
      component: () => import('@/views/gestCandidat/Surveillance/List'),
      name: 'Surveillance',
      meta: { title: 'Surveillance annuelle', icon: 'eye-open', noCache: true, roles: ['admin', 'candidat'], permissions: ['view menu gestion candidat'] },
    },
    {
      path: 'gestion_candidat/renouvellement',
      component: () => import('@/views/gestCandidat/Renouvellement/List'),
      name: 'Renouvellement',
      meta: { title: 'Renouvellement', icon: 'form', noCache: true, roles: ['admin', 'candidat'], permissions: ['view menu gestion candidat'] },
    },
  ],
};

export default gestCandidatRoutes;

