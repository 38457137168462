import Layout from '@/layout';

const dossierCandidatRoutes = {
  path: '',
  component: Layout,
  redirect: 'dashboard',
  meta: { roles: ['candidat'], permissions: ['view menu dossier candidat'] },
  children: [
    {
      path: 'certification',
      component: () => import('@/views/dossier_candidat/editor/home'),
      name: 'certification',
      meta: { title: 'Certification', icon: 'form', noCache: false, roles: ['candidat'], permissions: ['view menu dossier candidat'] },
    },
    {
      path: 'dossier/:certId/:formelleId',
      component: () => import('@/views/dossier_candidat/editor/demande_dossier'),
      name: 'Dossier',
      props: true,
      meta: { title: 'Demande de Certification', noCache: true, roles: ['candidat'], permissions: ['view menu dossier candidat'] },
      hidden: true,
    },
    {
      path: 'dossier_surveillance/:certId/:formelleId/:user_id/:surveillance',
      component: () => import('@/views/dossier_candidat/editor/Dossier_Surveillance'),
      name: 'Dossier Surveillance',
      props: true,
      meta: { title: 'DossierSurveillance', noCache: true, roles: ['candidat'], permissions: ['view menu dossier candidat'] },
      hidden: true,
    },
  ],
};

export default dossierCandidatRoutes;
