// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"menuText": "#555555",
	"menuActiveText": "#555",
	"subMenuActiveText": "#ee0000",
	"menuBg": "white",
	"menuHover": "#fff4f4",
	"subMenuBg": "white",
	"subMenuHover": "#e8e8e8",
	"sideBarWidth": "269px"
};
module.exports = exports;
