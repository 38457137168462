import Vue from 'vue';
import Router from 'vue-router';

/**
 * Layzloading will create many files and slow on compiling, so best not to use lazyloading on devlopment.
 * The syntax is lazyloading, but we convert to proper require() with babel-plugin-syntax-dynamic-import
 * @see https://doc.laravue.dev/guide/advanced/lazy-loading.html
 */

Vue.use(Router);

/* Layout */
import Layout from '@/layout';
/* Router for modules */
// import testget from './modules/testchapiroute';
import elementUiRoutes from './modules/element-ui';
import componentRoutes from './modules/components';
import chartsRoutes from './modules/charts';
import tableRoutes from './modules/table';
import adminRoutes from './modules/admin';
import nestedRoutes from './modules/nested';
import errorRoutes from './modules/error';
import excelRoutes from './modules/excel';
import permissionRoutes from './modules/permission';
import certificationRoutes from './modules/certification';
// import examRoutes from './modules/exam';
import centre_examsRoutes from './modules/centres_exams';
import gestionnaireDocument from './modules/document';
import responsableCertification from '@/router/modules/responsableCertification';
import responsableDossier from '@/router/modules/responsableDossier';
import examTheoriqueRoutes from '@/router/modules/exam_theorique';
import gestCandidatRoutes from '@/router/modules/gestion_candidat';
import dossierCandidatRoutes from '@/router/modules/dossier_candidat';
/**
 * Sub-menu only appear when children.length>=1
 * @see https://doc.laravue.dev/guide/essentials/router-and-nav.html
 **/

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin', 'editor']   Visible for these roles only
    permissions: ['view menu zip', 'manage user'] Visible for these permissions only
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb (default is true)
    affix: true                  if true, the tag will affix in the tags-view
  }
 **/

export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index'),
      },
    ],
  },
  {
    path: '/testget',
    component: () => import('@/views/testch/Testget'),
    meta: { auth: false },
    hidden: true,
  },
  {
    path: '/reset-password',
    component: () => import('@/views/password/ForgotPassword'),
    meta: { auth: false },
    hidden: true,
  },
  {
    path: '/reset-password/:token',
    component: () => import('@/views/password/ResetPasswordForm'),
    meta: { auth: false },
    hidden: true,
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
  {
    path: '/page_login',
    component: () => import('@/views/login/welcome_index'),
    meta: { auth: false },
    hidden: true,
  },
  {
    path: '/inscription',
    component: () => import('@/views/register/index'),
    hidden: true,
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/AuthRedirect'),
    hidden: true,
  },
  {
    path: '/404',
    redirect: { name: 'Page404' },
    component: () => import('@/views/error-page/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true,
  },
  {
    path: '/documentation',
    component: Layout,
    redirect: '/documentation/index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/documentation/index'),
        name: 'Documentation',
        meta: { title: 'documentation', icon: 'documentation', noCache: true },
      },
    ],
  },
  {
    path: '/guide',
    component: Layout,
    redirect: '/guide/index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/guide/index'),
        name: 'Guide',
        meta: { title: 'guide', icon: 'guide', noCache: true },
      },
    ],
  },
  elementUiRoutes,
];

export const asyncRoutes = [
  {
    path: '',
    component: Layout,
    redirect: 'dashboard',
    meta: {
      roles: ['admin', 'Responsable Cértificat'],
      permissions: ['view menu administrator'],
    },
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'dashboard',
        meta: { title: 'Tableau de bord', icon: 'dashboard', noCache: false },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: '/profile/edit',
    meta: { roles: ['admin', 'Responsable Dossier', 'Responsable Document'] },
    children: [
      {
        path: '/profile/edit',
        component: () => import('@/views/users/SelfProfile'),
        name: 'SelfProfile',
        meta: {
          title: "Profil de l'utilisateur",
          icon: 'user',
          noCache: true,
        },
      },
    ],
  },
  {
    path: '',
    // path: '/candidat/profile',
    component: Layout,
    //  redirect: 'candidat/profile',
    redirect: 'certification',
    meta: { roles: ['candidat'] },
    children: [
      {
        path: 'candidat/profile',
        component: () => import('@/views/candidat_profile/SelfProfile'),
        name: 'SelfProfile',
        meta: {
          title: 'Mon Compte',
          icon: 'user',
          noCache: true,
          permissions: ['view menu mon compte'],
        },
      },
    ],
  },
  // {
  //   path: '/categories',
  //   component: Layout,
  //   redirect: '/categories/index',
  //   meta: { permissions: ['manage category'] },
  //   children: [
  //     {
  //       path: 'categories/list',
  //       component: () => import('@/views/categories/List'),
  //       name: 'Gestion des catégories',
  //       meta: { title: 'Gestion des categories', icon: 'list', noCache: true, roles: ['admin', 'candidat'] },
  //     },
  //   ],
  // },
  // examRoutes,
  centre_examsRoutes,
  certificationRoutes,
  gestionnaireDocument,
  permissionRoutes,
  componentRoutes,
  chartsRoutes,
  nestedRoutes,
  tableRoutes,
  responsableCertification,
  responsableDossier,
  examTheoriqueRoutes,
  gestCandidatRoutes,
  adminRoutes,
  dossierCandidatRoutes,
  {
    path: '/historique_certification',
    component: Layout,
    redirect: 'noredirect',
    hidden: false,
    children: [
      {
        path: 'historique_certification',
        component: () => import('@/views/historique_certification/List'),
        name: 'Historique des candidats',
        meta: {
          title: 'Historique des candidats',
          icon: 'chart',
          roles: [
            'candidat',
            'admin',
            'Responsable Cértificat',
            'Responsable Dossier',
          ],
        },
      },
    ],
  },
  {
    path: '',
    component: Layout,
    redirect: 'noredirect',
    hidden: false,
    meta: {
      roles: ['admin', 'Responsable Cértificat', 'Responsable Dossier'],
    },
    children: [
      {
        path: 'parametre',
        component: () => import('@/views/directiveDemande/List'),
        name: 'Paramétre',
        meta: {
          title: 'Paramètres',
          icon: 'el-icon-s-operation',
          roles: ['admin'],
        },
      },
    ],
  },
  {
    path: '/theme',
    component: Layout,
    redirect: 'noredirect',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/theme/index'),
        name: 'Theme',
        meta: { title: 'theme', icon: 'theme' },
      },
    ],
  },
  {
    path: '/clipboard',
    component: Layout,
    redirect: 'noredirect',
    hidden: true,
    meta: { permissions: ['view menu clipboard'] },
    children: [
      {
        path: 'index',
        component: () => import('@/views/clipboard/index'),
        name: 'ClipboardDemo',
        meta: {
          title: 'clipboardDemo',
          icon: 'clipboard',
          roles: ['admin', 'manager', 'editor', 'candidat'],
        },
      },
    ],
  },
  errorRoutes,
  excelRoutes,
  {
    path: '/zip',
    component: Layout,
    redirect: '/zip/download',
    alwaysShow: true,
    hidden: true,
    meta: { title: 'zip', icon: 'zip', permissions: ['view menu zip'] },
    children: [
      {
        path: 'download',
        component: () => import('@/views/zip'),
        name: 'ExportZip',
        meta: { title: 'exportZip' },
      },
    ],
  },
  {
    path: '/pdf',
    component: Layout,
    redirect: '/pdf/index',
    hidden: true,
    meta: { title: 'pdf', icon: 'pdf', permissions: ['view menu pdf'] },
    children: [
      {
        path: 'index',
        component: () => import('@/views/pdf'),
        name: 'Pdf',
        meta: { title: 'pdf' },
      },
    ],
  },
  {
    path: '/pdf/download',
    component: () => import('@/views/pdf/Download'),
    hidden: true,
  },
  {
    path: '/i18n',
    component: Layout,
    hidden: true,
    meta: { permissions: ['view menu i18n'] },
    children: [
      {
        path: 'index',
        component: () => import('@/views/i18n'),
        name: 'I18n',
        meta: { title: 'i18n', icon: 'international' },
      },
    ],
  },
  {
    path: '/external-link',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'https://github.com/tuandm/laravue',
        meta: { title: 'externalLink', icon: 'link' },
      },
    ],
  },
  { path: '*', redirect: '/404', hidden: true },
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    base: process.env.MIX_LARAVUE_PATH,
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
