import Cookies from 'js-cookie';

const TokenKey = 'isLogged';
const IsverifiedKey = 'isVerified';

export function isLogged() {
  return Cookies.get(TokenKey) === '1';
}

export function setLogged(isLogged) {
  return Cookies.set(TokenKey, isLogged);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function isVerifiedEmail() {
  return Cookies.get(IsverifiedKey) === '1';
}

export function setVerifiedEmail(isVerified) {
  return Cookies.set(IsverifiedKey, isVerified);
}
