<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <div class="'fixed-header'">
        <navbar />
      </div>
      <!--      <div class="fixed-footer">-->
      <!--        <img style="width: 7%" src="/images/alm.png">-->
      <!--        <img style="width: 7%" src="/images/giz.png">-->
      <!--        <img style="width: 7%" src="/images/inorpi.png">-->
      <!--        <img style="width: 7%" src="/images/anme.png">-->
      <!--      </div>-->
      <!--<div class="row" style="background-color: #99cb84">-->
      <!--<div class="fixed-footer d-flex mt-5" style="">-->
      <!--<img class="my-auto size-image-footer mr-2" style="margin-left: 35%" src="/images/alm.png">-->
      <!--<img class="my-auto size-image-footer mr-5" src="/images/giz.png">-->
      <!--<img class="my-auto size-image-footer mr-5" src="/images/inorpi.jpeg">-->
      <!--<img class="my-auto mr-auto size-image-footer" src="/images/anme.png">-->
      <!--<p style="margin-top: 3.6%;font-size: 10px;">Copyright 2021 - Powered by <a href="https://tac-tic.net/"> Tac-Tic.</a></p>-->
      <!--</div>-->
      <!--</div>-->
      <app-main />
    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from './components';
import ResizeMixin from './mixin/resize-handler.js';
import { mapState } from 'vuex';

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    Sidebar,
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile',
      };
    },
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;

    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }
  .fixed-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    //width: 100%;
    // height: 122px;
    //height: 9%;
    background-color: #fff;
    box-shadow: 0 -1px 4px #c1c1c1;
    //transition-duration: 0.2s;
    z-index: 10;
    //display: block;
    text-align: center;
  }
  img {
    width: 9%;
  }
</style>
