/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const gestionnaireDocument = {
  path: '/gestionnaire_document',
  component: Layout,
  meta: { roles: ['admin', 'Responsable Document'] },
  children: [
    {
      path: 'document/list',
      component: () => import('@/views/document/List'),
      name: 'Gestion des document',
      meta: { title: 'Espace documentaire', icon: 'documentation', noCache: true },
    },
  ],
};

export default gestionnaireDocument;
