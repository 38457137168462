/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const responsableDossier = {
  path: '/responsable_dossier',
  redirect: '#',
  name: 'Responsable Dossier',
  component: Layout,
  meta: {
    permissions: ['view menu responsable dossier'],
    title: 'Revue et validation (RD)',
    icon: 'skill',
    roles: ['admin', 'Responsable Dossier'],
  },
  children: [
    {
      path: 'responsable/certification/list',
      component: () => import('@/views/responsableDossier/List'),
      name: 'Gestion des dossiers',
      meta: { title: 'Revue de la demande', icon: 'people', noCache: true, permissions: ['view menu responsable dossier'] },
    },
    {
      path: 'detail_certification/user/:id(\\d+)/:cert_id(\\d+)',
      component: () => import('@/views/responsableDossier/detail'),
      name: 'Detail',
      props: true,
      meta: { title: 'Detail', noCache: true },
      hidden: true,
    },
    {
      path: 'responsable/dossier/payement',
      component: () => import('@/views/responsableDossier/Payement'),
      name: 'Payement',
      meta: { title: 'Payement', icon: 'dollar', noCache: true, permissions: ['view menu responsable dossier'] },
    },
    {
      path: 'responsable/surveillance/list',
      component: () => import('@/views/responsableDossier/ListSurveillance'),
      name: 'Gestion des dossiers de surveillances',
      meta: { title: 'Revue de la surveillance', icon: 'eye', noCache: true, permissions: ['view menu responsable dossier'] },
    },
    {
      path: 'detail_surveillance/user/:id(\\d+)',
      component: () => import('@/views/responsableDossier/componentSurveillance/dossier_surveillance'),
      name: 'Surveillance',
      props: true,
      meta: { title: 'Surveillance', noCache: true },
      hidden: true,
    },
    {
      path: 'responsable/dossier_formelle',
      component: () => import('@/views/responsableDossier/components/dossier_formelle'),
      name: 'Dossier Formel',
      meta: { noCache: true, permissions: ['view menu responsable dossier'] },
      hidden: true,
    },
    {
      path: 'responsable/dossier_technique',
      component: () => import('@/views/responsableDossier/components/dossier_technique'),
      name: 'Dossier Technique',
      meta: { noCache: true, permissions: ['view menu responsable dossier'] },
      hidden: true,
    },
  ],
};

export default responsableDossier;
